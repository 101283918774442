import { ContactService } from './../contact.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms'
import {
	RecaptchaErrorParameters
} from "ng-recaptcha";

//#region Methods which are defined in the .js files.

declare function cargarWeb(): any;

//#endregion

@Component({
	selector: 'app-alta-cliente',
	templateUrl: './alta-cliente.component.html',
	styleUrls: ['./alta-cliente.component.css']
})
export class AltaClienteComponent implements OnInit {

	FormData: FormGroup;
	token: string;
	public cargando: boolean = false;
	public enviado: boolean = false;
	public error: boolean = false;


	constructor(private builder: FormBuilder, private contact: ContactService) { }

	ngOnInit(): void {
		cargarWeb();

		this.FormData = this.builder.group({
			razonsocial: new FormControl('', [Validators.required]),
			cuit: new FormControl('', [Validators.required]),
			condicioniva: new FormControl('', [Validators.required]),
			iibb: new FormControl('', [Validators.required]),
			nrohabilitacion: new FormControl('', [Validators.required]),
			domicilio: new FormControl('', [Validators.required]),
			telefono: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
			veterinario: new FormControl('', [Validators.required]),
			matricula: new FormControl('', [Validators.required]),
			comments: new FormControl('', [Validators.required]),
			captcha: new FormControl(null, Validators.required)
		})
	}

	ngOnDestroy(): void {

	}

	onSubmit(FormData) {
		let data = FormData.value;
		this.cargando = true;
		this.error = false;
		this.contact.PostSolicitud(data)
			.subscribe(response => {
				this.cargando = false;
				this.enviado = true;
				this.FormData.controls.razonsocial.reset();
				this.FormData.controls.cuit.reset();
				this.FormData.controls.condicioniva.reset();
				this.FormData.controls.iibb.reset();
				this.FormData.controls.nrohabilitacion.reset();
				this.FormData.controls.domicilio.reset();
				this.FormData.controls.telefono.reset();
				this.FormData.controls.email.reset();
				this.FormData.controls.veterinario.reset();
				this.FormData.controls.matricula.reset();
				this.FormData.controls.comments.reset();
				this.FormData.controls.captcha.reset();
			}, error => {
				console.log({ error })
				this.cargando = false;
				this.error = true;
			});
	}

	public resolved(captchaResponse: string): void {
		this.token = captchaResponse;
	}

	public onError(errorDetails: RecaptchaErrorParameters): void {
	}

}
