<!-- ======= Header ======= -->
<header id="header" class="fixed-top d-flex align-items-center">
	<div class="container" style="padding-left: 0px ;">
		<div class="header-container d-flex  justify-content-center align-items-center">

			<div class="logo mr-auto no-mostrar-logo" id="img-logo">
				<!-- <h1 class="text-light"><a hr-mostrar-lef="index.html"><span>Bethany</span></a></h1> -->
				<!-- Uncomment below if you prefer to use an image logo -->
				<a href="/index.html"><img src="assets/img/Logo-gris.png" alt="" class="img-fluid"></a>
			</div>

			<nav class="nav-menu d-none d-lg-block">
				<ul>
					<li class="active"><a routerLink="/home">Home</a></li>
					<li><a routerLink="/home/about">Quienes somos</a></li>
					<li><a routerLink="/home/services">Servicios</a></li>
					<li><a routerLink="/home/portfolio"> Marcas</a></li>
					<!--   <li><a href="#team">Equipo</a></li> -->
					<li class="drop-down"><a href="">Más</a>
						<ul>
							<li><a routerLink="/nuevo-cliente">QUIERO SER CLIENTE</a></li>
						</ul>
					</li>
					<li><a routerLink="/home/contact">Contacto</a></li>
				</ul>
			</nav><!-- .nav-menu -->
		</div><!-- End Header Container -->
	</div>
</header><!-- End Header -->

<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">
	<div class="container text-center position-relative" data-aos="fade-in" data-aos-delay="200">
		<h1><img src="assets/img/loco-completo-blanco1.png" width="50%" height="50%" alt="handler" class="img-fluid">
		</h1>
		<h2>¡IMPULSAMOS E INNOVAMOS NUESTROS NEGOCIOS JUNTOS!</h2>
	</div>
</section><!-- End Hero -->

<main id="main">
	<!-- ======= Nuevo Cliente Section ======= -->
	<section id="nuevo-cliente" class="contact">
		<div class="container">
			<div class="row">
				<div class="col-lg-4" data-aos="fade-right">
					<div class="section-title">
						<h2>Quiero ser cliente</h2>
						<p>Completa la solicitud con tus datos. Responderemos a la brevedad.</p>
						<br>
						<h4>REQUISITOS</h4>
						<p><i class="bx bxs-file-pdf" style="font-size: 1.5em;"></i> <a target="_blank" href="assets/RequisitosAperturaCuenta.pdf">Descargar</a>
						</p>
					</div>
				</div>

				<div class="col-lg-8" data-aos="fade-up" data-aos-delay="100">
					<form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData)" role="form"
						class="php-email-form mt-4">

						<div class="form-group">
							<input type="text" class="form-control" name="razonsocial" id="razonsocial"
								placeholder="Razon Social" formControlName="razonsocial" data-rule="minlen:4"
								data-msg="Por favor, ingrese al menos 4 caracteres" />
							<div class="validate"></div>
						</div>

						<div class="form-row">
							<div class="col-md-6 form-group">
								<input type="text" name="cuit" class="form-control" id="cuit" placeholder="CUIT"
									formControlName="cuit" data-rule="minlen:11"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>

							<div class="col-md-6 form-group">
								<input type="text" class="form-control" name="condicioniva" id="condicioniva"
									placeholder="Condición de IVA" formControlName="condicioniva" data-rule="minlen:4"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>
						</div>

						<div class="form-row">
							<div class="col-md-6 form-group">
								<input type="text" name="iibb" class="form-control" id="iibb"
									placeholder="Nro Ingresos Brutos" formControlName="iibb" data-rule="minlen:4"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>

							<div class="col-md-6 form-group">
								<input type="text" class="form-control" name="nrohabilitacion" id="nrohabilitacion"
									placeholder="Nro Habilitación Establecimiento" formControlName="nrohabilitacion"
									data-rule="minlen:4" data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>
						</div>

						<div class="form-row">
							<div class="col-md-12 form-group">
								<input type="email" class="form-control" name="domicilio" id="domicilio"
									placeholder="Domiclio" formControlName="domicilio" data-rule="minlen:4"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-6 form-group">
								<input type="email" class="form-control" name="email" id="email" placeholder="Email"
									formControlName="email" data-rule="email"
									data-msg="Por favor, ingrese un e-mail válido" />
								<div class="validate"></div>
							</div>
							<div class="col-md-6 form-group">
								<input type="text" class="form-control" name="telefono" id="telefono" placeholder="Teléfono"
									formControlName="telefono" data-rule="minlen:4"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>
						</div>
						<div class="form-row">
							<div class="col-md-6 form-group">
								<input type="text" class="form-control" name="veterinario" id="veterinario"
									placeholder="Veterinario" formControlName="veterinario" data-rule="minlen:4"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>
							<div class="col-md-6 form-group">
								<input type="text" class="form-control" name="matricula" id="matricula"
									placeholder="Nro. Matrícula " formControlName="matricula" data-rule="minlen:4"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>
						</div>
						<div class="form-group">
							<textarea class="form-control" name="comments" rows="5" data-rule="required"
								formControlName="comments" data-msg="Por favor, ingrese su mensaje"
								placeholder="Observaciones"></textarea>
							<div class="validate"></div>
						</div>
						<div class="form-group">
							<re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"
								siteKey="6LcULrEaAAAAANrXIPJaUAnVOO7i9mJhWiM9jH8T" formControlName="captcha">
							</re-captcha>
						</div>
						<div class="mb-3">
							<div *ngIf='cargando' class="loading">Cargando...</div>
							<div *ngIf='error' class="error-message">Lo sentimos se ha producido un error. Intente nuevamente!</div>
							<div *ngIf='enviado' class="sent-message">Su mensaje ha sido enviado. Muchas gracias!</div>
						</div>
						<div class="text-center"><button  type="submit">Enviar
								solicitud</button></div>
					</form>
				</div>
			</div>

		</div>
	</section> <!-- End Contact Section -->
</main><!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer">

	<div class="footer-top">
		<div class="container">
			<div class="row">

				<div class="col-lg-6 col-md-6 footer-contact">
					<a href="index.html"><img src="assets/img/logo-blanco-sf.png" alt="" class="img-fluid" width="45%"
							height="45%"></a>
					<hr>
					<p>
						Ruta Provincial 88 Km3, Nave 3. <br>
						Mar del Plata, Buenos Aires.<br>
						Argentina. <br><br>
						<strong>Telefono:</strong> +5492235554867<br>
						<strong>Email:</strong> contacto@handlermdp.com.ar<br>
					</p>
				</div>

				<div class="col-lg-6 col-md-6 footer-links">
					<h4>ENLACES ÚTILES</h4>
					<hr>
					<ul>
						<li><i class="bx bx-chevron-right"></i> <a routerLink="/home">Home</a></li>
						<li><i class="bx bx-chevron-right"></i> <a routerLink="/home/about">Quienes somos</a></li>
						<li><i class="bx bx-chevron-right"></i> <a routerLink="/home/services">Servicios</a></li>
						<li><i class="bx bx-chevron-right"></i> <a routerLink="/home/portfolio">Nuestras marcas</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<div class="container  py-4">

		<div class="mr-md-auto text-center text-md-center">
			<div class="copyright">
				&copy; Copyright <strong><span>Handler MDP</span></strong>. Todos los derechos reservados.
			</div>
			<div class="credits">
				Diseñado por <a target="_blank" href="https://ivai.com.ar/">IVAI</a>
			</div>
		</div>

	</div>
</footer><!-- End Footer -->

<a href="#/nuevo-cliente/#" class="back-to-top"><i class="icofont-simple-up"></i></a>