<!-- ======= Header ======= -->
<header id="header" class="fixed-top d-flex align-items-center">
	<div class="container" style="padding-left: 0px ;">
		<div class="header-container d-flex  justify-content-center align-items-center">

			<div class="logo mr-auto no-mostrar-logo" id="img-logo">
				<!-- <h1 class="text-light"><a hr-mostrar-lef="index.html"><span>Bethany</span></a></h1> -->
				<!-- Uncomment below if you prefer to use an image logo -->
				<a href="index.html"><img src="assets/img/Logo-gris.png" alt="" class="img-fluid"></a>
			</div>

			<nav class="nav-menu d-none d-lg-block">
				<ul>
					<li class="active"><a (click)="irA('top')">Home</a></li>
					<li><a (click)="irA('about')">Quienes somos</a></li>
					<li><a (click)="irA('services')">Servicios</a></li>
					<li><a (click)="irA('portfolio')"> Marcas</a></li>
					<li class="drop-down"><a href="">Más</a>
						<ul>
							<li><a routerLink="/nuevo-cliente">QUIERO SER CLIENTE</a></li>
						</ul>
					</li>
					<li><a (click)="irA('contact')">Contacto</a></li>

					<li class="get-started"><a href="#about">Empecemos</a></li>
				</ul>
			</nav><!-- .nav-menu -->
		</div><!-- End Header Container -->
	</div>
</header><!-- End Header -->

<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">
	<div class="container text-center position-relative" data-aos="fade-in" data-aos-delay="200">
		<h1><img src="assets/img/loco-completo-blanco1.png" width="50%" height="50%" alt="handler" class="img-fluid">
		</h1>
		<!--  <h1>Your New Online Presence with Bethany</h1> -->
		<h2>¡IMPULSAMOS E INNOVAMOS NUESTROS NEGOCIOS JUNTOS!</h2>
		<a href="#about" class="btn-get-started scrollto">Empecemos</a>
	</div>
</section><!-- End Hero -->

<main id="main">

	<!-- ======= About Section ======= -->
	<section id="about" class="about">
		<br>
		<div class="container">

			<div class="row content">
				<div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
					<h2>NOSOTROS</h2>
					<h3> Centramos nuestra atención en las necesidades de nuestros clientes.</h3>
				</div>
				<div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-delay="200"
					style="text-align: justify;">
					<p>
						HANDLER MDP es una distribuidora exclusiva de alimentos para mascotas de las marcas Royal
						Canin y Eukanuba. Somos un equipo de profesionales apasionado, que trabaja todos los días
						para satisfacer las necesidades de nuestros clientes. Atendiendo sus requerimientos con
						productos de las marcas de mayor calidad del mercado y servicios en un tiempo apropiado.
					</p>
				</div>
			</div>

		</div>
	</section><!-- End About Section -->

	<!-- ======= Counts Section ======= -->
	<section id="counts" class="counts">
		<div class="container">

			<div class="row counters">

				<div class="col-lg-4 col-6 text-center">
					<span data-toggle="counter-up">2</span>
					<p>Marcas</p>
				</div>

				<div class="col-lg-4 col-6 text-center">
					<span>+ </span> <span data-toggle="counter-up">255</span>
					<p>Referencias</p>
				</div>

				<div class="col-lg-4 col-6 text-center">
					<span>+ </span> <span data-toggle="counter-up">26</span>
					<p>Localidades</p>
				</div>

			</div>

		</div>
	</section><!-- End Counts Section -->

	<!-- ======= Why Us Section ======= -->
	<section id="why-us" class="why-us">
		<br>
		<div class="container">

			<div class="row">
				<div class="col-lg-4 d-flex align-items-stretch" data-aos="fade-right">
					<div class="content">
						<h3>Quienes somos?</h3>
						<p>
							Somos un equipo de apasionados, que entiende lo que nuestros
							clientes necesitan.
							<!--  <div class="text-center">
                <a href="#" class="more-btn">Learn More <i class="bx bx-chevron-right"></i></a>
              </div> -->
					</div>
				</div>
				<div class="col-lg-8 d-flex align-items-stretch">
					<div class="icon-boxes d-flex flex-column justify-content-center">
						<div class="row">
							<div class="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="100">
								<div class="icon-box mt-4 mt-xl-0">
									<i class="bx bx-target-lock"></i>
									<h4>MISIÓN</h4>
									<p>Conectar a nuestros clientes con los alimentos de mayor clase mundial en salud y
										bienestar
										para las mascotas.</p>
								</div>
							</div>
							<div class="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="200">
								<div class="icon-box mt-4 mt-xl-0">
									<i class="bx bxs-binoculars"></i>
									<h4>VISIÓN</h4>
									<p>Ser los referentes en la comercialización de alimento para mascotas.</p>
								</div>
							</div>
							<div class="col-xl-4 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay="300">
								<div class="icon-box mt-4 mt-xl-0">
									<i class="bx bx-rocket"></i>
									<h4>VALORES</h4>
									<p>Empatía, Agilidad y Confiabilidad.</p>
								</div>
							</div>
						</div>
					</div><!-- End .content-->
				</div>
			</div>

		</div>
	</section><!-- End Why Us Section -->

	<!-- ======= Cta Section ======= -->
	<section id="cta" class="cta">
		<div class="container">

			<div class="text-center" data-aos="zoom-in">
				<h3>Atención al cliente</h3>
				<p> Ofrecemos atención personalizada a través de nuestro servicio de atención al cliente y
					autogestión online, donde pueden encontrar información acerca de su cuenta corriente y
					realizar un pedido. Pueden enviar un correo electrónico a pedidos@handlermdp.com.ar </p>
				<a class="cta-btn" target="_blank" href="https://autogestion.handlermdp.com.ar">Ir a la autogestión</a>
			</div>

		</div>
	</section><!-- End Cta Section -->

	<!-- ======= Services Section ======= -->
	<section id="services" class="services section-bg">
		<br>
		<div class="container">

			<div class="row">
				<div class="col-lg-4">
					<div class="section-title" data-aos="fade-right">
						<h2>Servicios</h2>
					</div>
				</div>
				<div class="col-lg-8">
					<div class="row">
						<div class="col-md-6 d-flex align-items-stretch">
							<div class="icon-box" data-aos="zoom-in" data-aos-delay="100">
								<div class="icon"><i class="bx bx-current-location"></i></div>
								<h4><a href="">Logística</a></h4>
								<p>Entregamos productos de alta calidad desde las plantas de producción de nuestros
									proveedores hasta la puerta de nuestros clientes, manteniendo la eficiencia en todos
									los
									pasos.</p>
							</div>
						</div>

						<div class="col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
							<div class="icon-box" data-aos="zoom-in" data-aos-delay="200">
								<div class="icon"><i class="bx bx-support"></i></div>
								<h4><a href="">Representante de ventas</a></h4>
								<p>Con nuestra fuerza de venta usted puede obtener información acerca de nuestro
									portfolio y
									servicios y evaluar la mejor manera de evolucionar en su negocio.</p>
							</div>

						</div>

						<div class="col-md-6 d-flex align-items-stretch mt-4">
							<div class="icon-box" data-aos="zoom-in" data-aos-delay="300">
								<div class="icon"><i class="bx bx-comment-detail"></i></div>
								<h4><a href="">Asesoramiento Técnico Veterinario</a></h4>
								<p>Puede contactar a nuestro asesor técnico, para recibir información especifica acerca
									de
									nuestros productos o solicitar asesoramiento sobre cuál es la adecuada alimentación
									de
									acuerdo con la patología de su mascota. Nuestra misión es ayudarlos con una
									recomendación
									basada en el mejor conocimiento de nuestros productos.</p>
							</div>
						</div>

						<div class="col-md-6 d-flex align-items-stretch mt-4">
							<div class="icon-box" data-aos="zoom-in" data-aos-delay="400">
								<div class="icon"><i class="bx bxs-megaphone"></i></div>
								<h4><a href="">Marketing</a></h4>
								<p>Actualmente ofrecemos soporte a nuestros puntos de venta con el fin de brindarles
									conocimiento comercial sobre nuestros productos, exhibirlos correctamente a través
									de
									nuestro excelente merchandising, logrando notoriedad en la marca, obteniendo así la
									mejor
									exposición posible para nuestros productos y promociones dirigidas a los dueños de
									mascotas.</p>
							</div>
						</div>

					</div>
				</div>
			</div>

		</div>
	</section><!-- End Services Section -->

	<!-- ======= Portfolio Section ======= -->
	<section id="portfolio" class="portfolio">
		<br>
		<div class="container">

			<div class="section-title" data-aos="fade-left">
				<h2>Nuestras Marcas</h2>
				<p></p>
			</div>

			<div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

				<div class="col-lg-6 col-md-6 portfolio-item filter-app">
					<div class="portfolio-wrap">
						<img src="assets/img/portfolio/Royal-Canin-Logo.webp" class="img-fluid" alt="">
						<div class="portfolio-info">
							<h4>Royal Canin</h4>
							<div class="portfolio-links">
								<a href="https://www.royalcanin.com" title="Ir a Royal Canin" target="_blank"><i class="bx bx-link"></i></a>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-md-6 portfolio-item filter-web">
					<div class="portfolio-wrap">
						<img src="assets/img/portfolio/Eukanuba_Horizontal_Logo.webp" class="img-fluid" alt="">
						<div class="portfolio-info">
							<h4>Eukanuba</h4>
							<div class="portfolio-links">
								<a href="https://eukanuba.com.ar" title="Ir a Eukanuba"  target="_blank"><i class="bx bx-link"></i></a>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	</section><!-- End Portfolio Section -->

	<!-- ======= Contact Section ======= -->
	<section id="contact" class="contact">
		<br>
		<div class="container">
			<div class="row">
				<div class="col-lg-4" data-aos="fade-right">
					<div class="section-title">
						<h2>Contacto</h2>
						<p>Dejenos su consulta. Responderemos a la brevedad.</p>
					</div>
				</div>

				<div class="col-lg-8" data-aos="fade-up" data-aos-delay="100">
					<iframe style="border:0; width: 100%; height: 270px;"
						src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3143.4880971119446!2d-57.63538574869576!3d-38.01239905310966!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9584d914b2141193%3A0xca81f4d6267e6b94!2sHandler%20MDP!5e0!3m2!1ses-419!2sar!4v1618840585193!5m2!1ses-419!2sar"
						frameborder="0" allowfullscreen></iframe>
					<div class="info mt-4">
						<i class="icofont-google-map"></i>
						<h4>Domicilio:</h4>
						<p>Ruta Provincial 88 Km3, Nave 3</p>
					</div>
					<div class="row">
						<div class="col-lg-6 mt-4">
							<div class="info">
								<i class="icofont-envelope"></i>
								<h4>E-mail:</h4>
								<p>contacto@handlermdp.com.ar</p>
							</div>
						</div>
						<div class="col-lg-6">
							<div class="info w-100 mt-4">
								<i class="icofont-phone"></i>
								<h4>Teléfono:</h4>
								<p>+5492235554867</p>
							</div>
						</div>
					</div>

					<form [formGroup]="FormData" (ngSubmit)="onSubmit(FormData)" role="form"
						class="php-email-form mt-4">
						<div class="form-row">
							<div class="col-md-6 form-group">
								<input type="text" name="name" class="form-control" id="name" placeholder="Nombre"
									formControlName="name" data-rule="minlen:4"
									data-msg="Por favor, ingrese al menos 4 caracteres" />
								<div class="validate"></div>
							</div>
							<div class="col-md-6 form-group">
								<input type="email" class="form-control" name="email" id="email" placeholder="Email"
									formControlName="email" data-rule="email"
									data-msg="Por favor, ingrese un e-mail válido" />
								<div class="validate"></div>
							</div>
						</div>
						<div class="form-group">
							<input type="text" class="form-control" name="subject" id="subject" placeholder="Asunto"
								formControlName="subject" data-rule="minlen:4"
								data-msg="Por favor, ingrese al menos 4 caracteres" />
							<div class="validate"></div>
						</div>
						<div class="form-group">
							<textarea class="form-control" name="comments" rows="5" data-rule="required"
								formControlName="comments" data-msg="Por favor, ingrese su mensaje"
								placeholder="Mensaje"></textarea>
							<div class="validate"></div>
						</div>
						<div class="form-group">
							<re-captcha (resolved)="resolved($event)" (error)="onError($event)" errorMode="handled"
							siteKey="6LcULrEaAAAAANrXIPJaUAnVOO7i9mJhWiM9jH8T" formControlName="captcha"></re-captcha>
						</div>
						<div class="mb-3">
							<div *ngIf='cargando' class="loading">Cargando...</div>
							<div *ngIf='error' class="error-message">Lo sentimos se ha producido un error. Intente nuevamente!</div>
							<div *ngIf='enviado' class="sent-message">Su mensaje ha sido enviado. Muchas gracias!</div>
						</div>
						
						<div class="text-center"><button [disabled]="!FormData.valid" type="submit">Enviar
								mensaje</button></div>
					</form>
				</div>
			</div>

		</div>
	</section> <!-- End Contact Section -->
</main><!-- End #main -->

<!-- ======= Footer ======= -->
<footer id="footer">

	<div class="footer-top">
		<div class="container">
			<div class="row">

				<div class="col-lg-6 col-md-6 footer-contact">
					<a href="index.html"><img src="assets/img/logo-blanco-sf.png" alt="" class="img-fluid" width="45%"
							height="45%"></a>
					<!-- <h3>Bethany</h3> -->

					<hr>
					<p>
						Ruta Provincial 88 Km3, Nave 3. <br>
						Mar del Plata, Buenos Aires.<br>
						Argentina. <br><br>
						<strong>Telefono:</strong> +5492235554867<br>
						<strong>Email:</strong> contacto@handlermdp.com.ar<br>
					</p>
				</div>

				<div class="col-lg-6 col-md-6 footer-links">
					<h4>ENLACES ÚTILES</h4>
					<hr>
					<ul>
						<li><i class="bx bx-chevron-right"></i> <a (click)="irA('top')">Home</a></li>
						<li><i class="bx bx-chevron-right"></i> <a (click)="irA('about')">Quienes somos</a></li>
						<li><i class="bx bx-chevron-right"></i> <a (click)="irA('services')">Servicios</a></li>
						<li><i class="bx bx-chevron-right"></i> <a (click)="irA('portfolio')">Nuestras marcas</a></li>
					</ul>
				</div>
			</div>
		</div>
	</div>

	<div class="container  py-4">

		<div class="mr-md-auto text-center text-md-center">
			<div class="copyright">
				&copy; Copyright <strong><span>Handler MDP</span></strong>. Todos los derechos reservados.
			</div>
			<div class="credits">
				Diseñado por <a target="_blank" href="https://ivai.com.ar/">IVAI</a>
			</div>
		</div>
	</div>
</footer><!-- End Footer -->

<a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>