import { BaseComponent } from './base/base.component';
import { AltaClienteComponent } from './alta-cliente/alta-cliente.component';


import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppComponent } from './app.component';


const routes: Routes = [
	{ path: '', redirectTo: '/home', pathMatch: 'full' },
	{
		path: 'home',
		component: BaseComponent,
		children: [
			{ path: '', component: AppComponent },
			{ path: ':seccion', component: AppComponent }
		]
	},
	{ path: 'nuevo-cliente', component: AltaClienteComponent },
	{ path: '**', redirectTo: '/home', pathMatch: 'full' }
];


@NgModule({
	imports: [RouterModule.forRoot(routes, { useHash: true })],
	exports: [RouterModule]
})
export class AppRoutingModule { }
