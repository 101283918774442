import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms'
import { ActivatedRoute } from '@angular/router';
import {
	RecaptchaErrorParameters
} from "ng-recaptcha";
import { ContactService } from './contact.service';

//#region Methods which are defined in the .js files.

declare function cargarWeb(): any;

//#endregion

export interface FormModel {
	captcha?: string;
}


@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {

	FormData: FormGroup;
	token: string;
	public cargando: boolean = false;
	public enviado: boolean = false;
	public error: boolean = false;

	constructor(private builder: FormBuilder, private contact: ContactService, private rutaActiva: ActivatedRoute) { }
	
	ngAfterViewInit(): void {
		let seccion = this.rutaActiva.snapshot.params['seccion'];
		if (seccion) {
			this.irA(seccion);
		}
	}

	public irA(seccion: any) {
		if (seccion === 'top') {
			window.scroll({
				top: 0, 
				left: 0, 
				behavior: 'smooth'
			  });
			return;
		}

		document.getElementById(seccion).scrollIntoView({behavior: 'smooth'});
	}

	ngOnInit(): void {
		cargarWeb();

		this.FormData = this.builder.group({
			name: new FormControl('', [Validators.required]),
			email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
			subject: new FormControl('', [Validators.required]),
			comments: new FormControl('', [Validators.required]),
			captcha: new FormControl(null, Validators.required)
		})
	}

	ngOnDestroy(): void {

	}

	title = 'web';


	onSubmit(FormData) {
		let data = FormData.value;
		this.cargando = true;
		this.error = false;
		this.contact.PostMessage(data)
			.subscribe(response => {
				this.cargando = false;
				this.enviado = true;
				this.FormData.controls.name.reset();
				this.FormData.controls.email.reset();
				this.FormData.controls.subject.reset();
				this.FormData.controls.comments.reset();
				this.FormData.controls.captcha.reset();
			}, error => {
				console.log({ error })
				this.cargando = false;
				this.error = true;
			});
	}

	public resolved(captchaResponse: string): void {
		this.token = captchaResponse;
	}

	public onError(errorDetails: RecaptchaErrorParameters): void {
	}
}
