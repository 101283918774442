import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators'
import { HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class ContactService {

	private mailApi = 'https://www.handlermdp.com.ar/forms/';
	constructor(private http: HttpClient) { }
  
	PostMessage(input: any) {
	  return this.http.post(this.mailApi + 'enviar-contacto.php', input, { responseType: 'text' })
		.pipe(
		  map(
			(response) => {
			  if (response) {
				return response;
			  }
			},
			(error: any) => {
			  return error;
			}
		  )
		)
	}


	PostSolicitud(input: any) {
		return this.http.post(this.mailApi + 'enviar-solicitud.php', input, { responseType: 'text' })
		  .pipe(
			map(
			  (response) => {
				if (response) {
				  return response;
				}
			  },
			  (error: any) => {
				return error;
			  }
			)
		  )
	  }
}
